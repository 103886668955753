<template>
<div>
	<el-dialog :title="dialogTitle" :visible.sync='dialogAddVisible' width="60%" :close-on-click-modal="false" :modal-append-to-body="false" validate-on-rule-change="true" >
		<el-form  label-position="top"  ref="form" :model="form" prop="" >	
				<el-row class="field-content">
					<div v-for="(group,index) in groupData"> 
						 <el-col  v-if="group.group_name" :span="23" style="border-bottom:1px solid #E4E7ED;">  
							<p  style="font-weight: bold;font-size: 16px;border-bottom: 3px solid #409EFF;display: inline-block;">{{group.group_name}}</p>
						</el-col> 
						<div v-for="(item,childIndex) in group.data" >
						  <el-col :span="8"  v-if="item.type !== 1 && item.type !== 4"> 
							  <el-form-item  :label="item.title" :required="item.required_status?true:false">
								  	<!-- 普通文本 -->
									<el-input  v-if="item.type < 1" style="width: 280px;" v-model="item.value" 
										:disabled="item.field_name ==='receipt_number' || item.field_name === 'admin_id'?true:false" 
										:placeholder="item.field_name ==='receipt_number'?'编码自动生成':item.title" ></el-input>
									
									<!-- 金额 -->
									 <el-input-number  v-if="item.type == 11" v-model="item.value" :precision="2" :step="0.1" :min="1"  controls-position="right"></el-input-number>
									<!-- 下拉框 -->
									<!-- 部门、成员数据下拉 -->
									<template  v-if="item.type == 9">
										<!-- 部门 -->
										<!-- <el-select v-if="item.field_name == 'dept_id'" v-model="item.value"   placeholder="请选择"  style="width: 280px;" @change="handleChangeSelect">
										  <el-option v-for="(dept,deptIndex) in deptList"  :key="dept.id" :value="dept.id" :label="dept.name"> </el-option>
										</el-select> -->
										<el-cascader
											 v-if="item.field_name == 'dept_id'"
											v-model="item.value"   
											 :props="props"  
											:options="deptList"  
											  @change="handleChangeDept"
											  ref="cascader"  
											></el-cascader> 
										<!-- 部门成员 -->
										<el-select 
										filterable 
										v-else-if="item.field_name == 'user_id'" 
										v-model="item.value"   
										placeholder="请选择"  
										style="width: 280px;">
											<!-- <template  v-for="dept in deptList">
												 <el-option
												 v-for="item2 in dept.children"
												 v-if="item2.department == deptId"
												:label="item2.label"
												:value="item2.id">
												</el-option>
											</template>			  -->
												 <el-option
												  v-for="admin in adminList"
												 :label="admin.name"
												 :value="admin.id">
												 </el-option>
										</el-select>
										<!-- 调出/调入管理员 -->
										<el-select filterable  v-else-if="item.field_name == 'out_admin_id' 
												|| item.field_name == 'in_admin_id'" 
												v-model="item.value"   
												 placeholder="请选择"  style="width: 300px;">										
												 <el-option
												 v-for="admin in adminList"
												:label="admin.name" 
												:value="admin.id">
												</el-option> 
										</el-select>
										<el-select  v-if="selectionStatus && item.field_name == 'location_id'"  ref="treeSelect"
											v-model="item.temp_select_name" placeholder="请选择" clearable 
											 @change="(val)=>handleSelect(val,index,childIndex)">
												<el-option value="0" label="无上级">无上级</el-option>
												<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
												 highlight-current ref="tree" 
												 @node-click="(val)=>handleTreeNodeClick(val,index,childIndex)"> </el-tree>
										</el-select>
										<el-select v-if="
													item.field_name !== 'out_admin_id' 
													&& item.field_name !== 'in_admin_id'
													&&  item.field_name !== 'dept_id'
													&&  item.field_name !== 'user_id'
													&&  item.field_name !== 'location_id'
													" v-model="item.value"   placeholder="请选择"  style="width: 280px;">
										  <el-option v-for="(cItem,cIndex) in item.content" :label="cItem.name" :value="cIndex">
										  </el-option>
										</el-select>
									</template>
									<!-- 日期选择 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 6"
										v-model="item.value"
										type="date"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 时间日期 -->
									<el-date-picker
										style="width: 280px;"
										v-if="item.type == 5"
										v-model="item.value"
										type="datetime"
										placeholder="选择日期">
									</el-date-picker>
									<!-- 金额 -->
									<el-input-number v-if="item.type == 1" v-model="item.value" :precision="2" :step="0.1" :max="10"></el-input-number>
							  </el-form-item>   
						  </el-col>
						   <el-col :span="24" v-if="item.type == 1 || item.type == 4">
							   <!-- 多行文本 -->
							   <el-form-item v-if="item.type == 1" :label="item.title"
									:required="item.required_status?true:false">
									<el-input type="textarea" rows="5"  v-model="item.value" placeholder="" ></el-input>
							   </el-form-item> 
							   <el-form-item v-if="item.type == 4" :label="item.title" 
									:required="item.required_status?true:false">
										<el-upload
										  action="https://jsonplaceholder.typicode.com/posts/"
										  list-type="picture-card"
										 >
										  <i class="el-icon-plus"></i>
										</el-upload>
								</el-form-item> 						
							</el-col>
						  </div>
					</div>
				</el-row>
		</el-form>
		<!-- :row-key="getRowKeys" -->
		 <!-- @selection-change="handleSelectionChange" -->
		 
		 <template v-if="storageType != 1 && storageType != 10">
			<p class="select-asset" @click="handleSelectAsset">选择资产</p>
			<el-table
				border
				:data="tableData" 
				:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
				:row-style="{height:'40px'}"
				 :cell-style="{padding: '0'}" 
				style="width: 100%;">
				<template v-for="item in theadData">
					<el-table-column :key="item.field_name" :label="item.label">
						<template slot-scope="scope">
							<span v-if="item.field_name == 'cate_id'">{{scope.row['cate_name']}}</span>
							<span v-if="item.field_name == 'location_id'">{{scope.row['location_name']}}</span>
							<span v-if="item.field_name == 'dept_id'">{{scope.row['dept_name']}}</span>
							<span v-if="item.field_name == 'user_id'">{{scope.row['user_name']}}</span>
							<span v-if="item.field_name !== 'cate_id'
								&& item.field_name !== 'location_id' 
								&& item.field_name !== 'dept_id'
								&& item.field_name !== 'user_id'">{{scope.row[item.field_name]}}</span>
						</template> 
					</el-table-column>
				</template>
				<el-table-column
					label="操作"
					>
					<template slot-scope="scope">
						 <el-button
							  @click.native.prevent="deleteRow(scope.$index, tableData)"
							  type="text"
							  size="small">
							  移除
						</el-button> 
					</template> 
				</el-table-column>
			</el-table> 
		 </template>
		<template v-if="storageType == 1 || storageType == 10">
			<p  v-if="storageType == 10" class="select-asset" @click="handleSelectAsset">选择资产</p>
			<el-table
				v-if="tableStatus"
				class="storage-table-box"
				border
				:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
				:row-style="{height:'40px'}"
				 :cell-style="{padding: '0'}" 
				:data="tableData" 
				style="width: 100%;">
				<template v-for="(item,thIndex) in theadData">
					<el-table-column
					v-if="thIndex == 0"
					width="80px"
					>
					<template slot-scope="scope">
						{{scope.$index + 1}}
					</template>
					</el-table-column>
					<el-table-column
					  width="120px"
					 :key="item.field_name" :label="item.label">
						<template slot-scope="scope">
							<div class="add-table-row-box">
								<el-input style="width: 100%;border: none;" v-if="(item.type == 0 || item.type == 1)&& item.field_name != 'asset_code'"
									v-model="scope.row[item.field_name]" 
									:placeholder="item.label" ></el-input>
								<el-input style="width: 100%;border: none;" v-if="item.type == 0 && item.field_name == 'asset_code'"
									v-model="scope.row[item.field_name]" 
									:placeholder="assetCodeStatus?'自动编码':item.label" :disabled="assetCodeStatus"></el-input>	
									
								 <el-date-picker
									style="width: 280px;"
									v-if="item.type == 6"
									v-model="scope.row[item.field_name]"
									type="date"
									placeholder="选择日期">
								</el-date-picker>
								<template  v-if="item.type == 9">
									<el-select  v-if="item.field_name == 'location_id'"  ref="treeSelect"
										v-model="scope.row['location_name']" placeholder="请选择" clearable 
										 @change="(val)=>handleSelect(val,index,scope.$index)">
											<el-option value="0" label="无上级">无上级</el-option>
											<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
											 highlight-current ref="tree" 
											 @node-click="(val)=>handleNodeClick(val,scope.$index,'location_id')"> </el-tree>
									</el-select>
									<el-select  v-if="item.field_name == 'cate_id'"  ref="treeSelect"
										v-model="scope.row['cate_name']" placeholder="请选择" clearable 
										 @change="(val)=>handleSelect(val,index,childIndex)">
											<el-option value="0" label="无上级">无上级</el-option>
											<el-tree   node-key="id" :data="cateTreeData" :props="treePorps"
											 highlight-current ref="tree" 
											 @node-click="(val)=>handleNodeClick(val,scope.$index,'cate_id')"> </el-tree>
									</el-select>
									<el-select  v-if="item.field_name == 'dept_id'"  ref="treeSelect"
										v-model="scope.row['dept_name']" placeholder="请选择" clearable 
										 @change="(val)=>handleSelect(val,index,childIndex)">
											<el-option value="0" label="无上级">无上级</el-option>
											<el-tree   node-key="id" :data="deptList" :props="treePorps"
											 highlight-current ref="tree" 
											 @node-click="(val)=>handleNodeClick(val,scope.$index,'dept_id')"> </el-tree>
									</el-select>
									<el-select v-else-if="item.field_name == 'user_id'" 
										filterable 
										v-model="scope.row['user_id']"   
										placeholder="请选择">
										<template  v-for="admin in adminList">
											 <el-option
											:label="admin.name" 
											:value="admin.id">
											</el-option>
										</template>			               
									</el-select> 
									<el-select v-if="
										item.field_name !== 'cate_id' 
										&& item.field_name !== 'location_id'
										&& item.field_name !== 'dept_id'
										&& item.field_name !== 'user_id'" 
										v-model="scope.row[item.field_name]"   placeholder="请选择"  style="width: 280px;">
									  <el-option v-for="(sItem,sIndex) in item.items" :label="sItem['name']" :value="sIndex"></el-option>
									</el-select> 
								</template>
							</div> 
						</template>
					</el-table-column>
				</template>
				<el-table-column
					width="100px"
					label="操作"
					>
					<template slot-scope="scope">
						<el-button
							  @click.native.prevent="addNewRow(scope.$index, tableData)"
							  type="text"
							  size="small">
							  添加
						</el-button>
						 <el-button
							  @click.native.prevent="deleteRow(scope.$index, tableData)"
							  type="text"
							  size="small">
							  移除
						</el-button>
					</template>
				</el-table-column>
			</el-table> 
		</template>
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialogAddVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
		<SelecteAssetVue ref="selectAsset" :tableData.sync="tableData"></SelecteAssetVue>
	</el-dialog>
</div>
</template>

<script>
import SelecteAssetVue from '../Form/SelectAsset.vue'
export default {
	inject:['reload'],
	components:{
	    SelecteAssetVue,
	},
    data() {
        return {
			dialogVisible:false,
			checkboxGroup:[],
			dialogAddVisible:false,
			selectionStatus:true,
			cateTreeData:[],
			locationTreeData:[],
			groupData:[], 
			fieldData:[],
			cardName:'',
			cardType:2,
			form:{},
			theadData:[],
			tableData:[],
			deptList:[],
			deptId:'',
			dialogTitle:'',
			addUrl:'',
			editUrl:'',
			storageType:0,
			assetCodeStatus:false,
			newRow:{},
			treePorps:{
				label:'name',
			},
			props:{
				value:'id',
				label:'name',
			},
			tableStatus:true,
			adminList:[],		//管理员列表
			staffStorageId:0,		//员工申请的单据id 传递时代表从员工单据里请求操作 后续绑定关联
			admin_status:1,
        }
    },
    created() {

    },
	watch: {
		'tableData'() {
			this.tableStatus =  false
			this.$nextTick(()=>{
				this.tableStatus  = true
				
			})
		},
	},
    mounted() {
		
    },
    methods:{
		handleAdd(id=0){ 
			if (id < 1) { 
				// console.log('s_type:',this.storageType,',card_name:',this.cardName) 
				this.dialogTitle =  '添加'+this.cardName
				this.$api.get('AssetForm/getFieldAndTable', {
					name:this.cardName,
					type:this.cardType
				}, res => {
					if (res.code == 200) {
						this.groupData =  res.data.group_data
						this.theadData =  res.data.thead_data
						if(this.storageType == 1){		
							if(this.staffStorageId < 1 || this.tableData.length < 1){
								this.tableData =  res.data.empty_table_data
							}
							this.newRow =  res.data.empty_table_data[0]
						}
						this.assetCodeStatus = res.data.asset_code_status?true:false					
						this.checkboxGroup =  res.data.default_checked
						
						//获取部门和人员数据
						// this.$api.get('admin/deptList',null,res=>{
						//     if(res.code == 200){
						//         // this.adminList  = res.data
						// 		this.deptList =  res.data
						// 	}
						// })
						
						this.$api.get('Department/deptList',null,deptRes=>{
							if(deptRes.code == 200){
								this.deptList =  deptRes.data.list
							}
						})
						
						//获取资产位置和资产分类
						this.$api.get('AssetCategory/treeData',null,res=>{
						    if(res.code == 200){
								this.cateTreeData =  res.data.tree_list
							}
							
						})
						
						//管理员列表
						this.$api.get('admin/getDeptAdmin',null,res=>{
						     if(res.code == 200){
								this.adminList =  res.data.list
						     }else{
						         this.$message.error(res.msg)
						     }
						 })
						this.$api.get('AssetLocation/treeData',null,res=>{
						    if(res.code == 200){
								this.locationTreeData =  res.data.tree_list
								// console.log('location:',this.locationData)
							}
						})
						this.dialogAddVisible = true
					}
				})
			} else {
				// this.handleEdit(id)
			}
		},
		
		
		//添加新行
		addNewRow(index,table_data){
			this.tableStatus = false
			this.$nextTick(()=>{
				this.tableStatus = true
				this.tableData.push(this.newRow)
			})
		},
		
		//监听资产分类/位置选择
		handleNodeClick(val,index,name){
			this.tableStatus = false
			this.$nextTick(()=>{
					this.tableStatus = true
					if(name == 'location_id'){
						this.tableData[index]['location_name'] = val.name
						this.tableData[index]['location_id'] = val.id
					}
					if(name == 'cate_id'){
						this.tableData[index]['cate_name'] = val.name
						this.tableData[index]['cate_id'] = val.id
					}
					if(name == 'dept_id'){
						this.tableData[index]['dept_name'] = val.name
						this.tableData[index]['dept_id'] = val.id
						
						this.$api.get('admin/getDeptAdmin',{dept_id:val.id},res=>{
						     if(res.code == 200){
								this.adminList =  res.data.list
						     }else{
						         this.$message.error(res.msg)
						     }
						 })
					}
			})
		},
		handleTreeNodeClick(data,index,childIndex){
			this.selectionStatus =  false
			this.$nextTick(()=>{
				this.selectionStatus =  true
				this.groupData[index]['data'][childIndex]['value'] = data.id
				this.groupData[index]['data'][childIndex]['temp_select_name'] = data.name
				
				// console.log('groupdata:',this.groupData)
			})
		},
		handleSelectAsset(){
			this.$nextTick(()=>{
				this.$refs.selectAsset.storageType =  this.storageType
				this.$refs.selectAsset.showAsset(this.tableData) 
			})
		
		},
		deleteRow(index, rows) {
			rows.splice(index, 1);
		},
		
		//监听部门选择 更新部门成员数据
		handleChangeDept(value){
			let dept_id = value[value.length-1]    
			 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
				  if(res.code == 200){  
						this.adminList =  res.data.list
				  }else{
					  this.$message.error(res.msg)
				  } 
			  })
		},
		//获取提交接口
		getAddUrl(add_status = true){
			var type =  this.storageType
			var url = ''
			switch(type){
				case 2:		//派发
				case 3:		//退库
					url = 'AssetDist/add'
					break;
				case 4:		//借出
				case 5:		//归还
					url = 'AssetLoan/add'
					break;
				case 6:		//变更
					url = 'AssetChangeUser/add'
					break;
				case 7:		//调拨
					url = 'AssetAllot/add'
					break;
				// case 8:		//维修
				// 	url = 'AssetDist/add'
				// 	break;
				case 9:		//处置
					url = 'AssetDisposal/add'
					break; 
				// case 9:		//修改
				// 	url = 'AssetDist/add'
				// 	break;
			}
			
			return url
		},
		confirm(){
		   let url = this.addUrl
			if (this.form.id) {
				url = this.editUrl
			}
			if(!url){
				url  = this.getAddUrl()
				if(!url){
					this.$message.error('请求接口出现空值，请联系管理员')
				}
			}
			// console.log('confirm:', this.storageType)
			this.form.field_data =  this.groupData
			this.form.asset_list_id = this.tableData
			this.form.type =  this.storageType
			this.form.staff_storage_id = this.staffStorageId
			console.log(this.form)
			this.$api.post(url, this.form, res => { 
				if (res.code == 200) {
					this.dialogAddVisible = false
					this.$message.success(res.msg)
					this.reload();
					this.$refs["form"].resetFields();
		  
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleChangeSelect(val){
			this.deptId  = val
		},
		
    },
}
</script>
<style lang="scss">
	.storage-table-box{
		.cell{
			padding: 0 !important;
			text-align: center;
		}
		.add-table-row-box{
			// width: 120px;
			.el-input__inner{
				border: none !important;
			}
		}
	}

</style>
<style scoped lang="scss">
	.select-asset{
		color: #409EFF;
		font-weight: 600;
		font-size: 14px;
		text-align: right;
	}
	
</style>