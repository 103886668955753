<template>
<div>
	<el-dialog title="选择资产" :visible.sync='dialogAssetVisible' width="35%" :close-on-click-modal="false"  :modal-append-to-body="false" :append-to-body="true">
		<el-table 
			border 
			ref="multipleTable"
			@selection-change="handleSelectionChange" 
			row-key="asset_code"
			:data="tableData" 
			style="width:100%">
			<el-table-column
			     type="selection"
				:reserve-selection="true"
			     width="55">
			   </el-table-column>
			<template v-for="item in theadData">	
				<el-table-column :width="item.width" :key="item.field_name" :label="item.label">
					<template slot-scope="scope">
						<span v-if="item.field_name == 'cate_id'">{{scope.row.cate_name}}</span>
						<span v-if="item.field_name == 'location_id'">{{scope.row.location_name}}</span>
						<div v-if="item.field_name == 'status'">
							<!-- <el-tag type="success" v-if="scope.row[item.field_name] == 1">空闲</el-tag>
							<el-tag v-if="scope.row[item.field_name] == 2">派发中</el-tag>
							<el-tag type="warning" v-if="scope.row[item.field_name] == 3">在用</el-tag>
							<el-tag type="danger" v-if="scope.row[item.field_name] == 4">归还</el-tag>
							<el-tag type="warning" v-if="scope.row[item.field_name] == 5">借用</el-tag>
							<el-tag type="danger" v-if="scope.row[item.field_name] == 6">领用申请中</el-tag> -->
							<el-tag type="success" v-if="scope.row[item.field_name] == 1">空闲</el-tag>
							<el-tag v-if="scope.row[item.field_name] == 2">派发中</el-tag>
							<el-tag type="warning" v-if="scope.row[item.field_name] == 3">退库中</el-tag>
							<el-tag type="danger" v-if="scope.row[item.field_name] == 4">出借中</el-tag>
							<el-tag type="warning" v-if="scope.row[item.field_name] == 5">归还中</el-tag>
							<el-tag  v-if="scope.row[item.field_name] == 6">变更中</el-tag>
							<el-tag type="danger" v-if="scope.row[item.field_name] == 7">调拨中</el-tag>
							<el-tag type="danger" v-if="scope.row[item.field_name] == 8">维修中</el-tag>
							<el-tag  v-if="scope.row[item.field_name] == 9">处置中</el-tag>
							<el-tag  v-if="scope.row[item.field_name] == 10">修改中</el-tag>
							<el-tag type="danger" v-if="scope.row[item.field_name] == 11">已报失</el-tag>
							<el-tag  v-if="scope.row[item.field_name] == 12">在用</el-tag>
						</div>
						<span v-else>{{scope.row[item.field_name]?scope.row[item.field_name]:'-'}}</span>
					</template>
				</el-table-column>
			</template>
		</el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="subTable()">确 定</el-button>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
			dialogAssetVisible:false,	
			tableData:[],
			theadData:[],
			form: {
				name: ''
			},
			currentPage: 1,
			pagesize: 10,
			total: 0,
			checkedData:[],
			rows:[],
			storageType:0
        }
    },
    created() {
		
    },
    mounted() {

    },
    methods:{
		showAsset(rows=[]){
			this.rows = rows
			console.log(this.rows)
			this.form['page'] = this.currentPage-1
			this.form['limit'] = this.pagesize
			this.form['storage_type'] = this.storageType
			this.$api.post('AssetList/index',this.form, res => {
				if (res.code == 200) {
					this.tableData = res.data.list ?? []
					this.total = res.data.count
					this.theadData = res.data.thead
					this.data = res.data.thead
					this.dialogAssetVisible  = true
					this.$nextTick(()=>{
						if(rows){
							rows.forEach(row => {
								this.$refs.multipleTable.toggleRowSelection(row,true);
								// this.$refs.multipleTable.toggleRowSelection(
								// 	this.tableData.find(item=>{ return row.asset_code == item.asset_code}
								// ),true);
							});
							
						}
					})
				}
			})
		},
		getKey(row){
			return row.asset_code
		},
		subTable(){
			// console.log(this.checkedData)
		
			this.$emit('update:tableData',this.checkedData)
			this.dialogAssetVisible = false
		},
		handleSelectionChange(val){
			this.checkedData =  val
			// console.log('check:',this.checkedData )
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.showAsset()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		    this.showAsset()
		},
    },
}
</script>
<style scoped>

</style>